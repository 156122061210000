
/**!
 *  Youtube player.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./youtubeplayer.scss";

import LoadImage from "Components/Layout/LoadImage";
import { default as Player } from "react-youtube";

class YoutubePlayer extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            id: false,
            ready: false,
            show: false

        };

        this.Options = {

            // Player options.
            // https://developers.google.com/youtube/player_parameters
            playerVars: {

                autoplay: true,
                color: "white",
                iv_load_policy: 3,
                modestbranding: 1,
                showinfo: 0

            }

        };

    }

    /**
     * Set YouTube video ID on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { id, url } = this.props;

        this.setState( {

            id: id || this.ParseUrl( url )

        } );

    }

    /**
     * Update the video credentials.
     * 
     * @param object nextProps - The updated component properties.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { id: i1, url: u1 } = nextProps;
        const { id: i2, url: u2 } = this.props;

        if ( i1 !== i2 || u1 !== u2 ) {

            this.setState( {

                id: i1 || this.ParseUrl( u1 )

            } );

        }

    }

    /**
     * Callback for when the poster (preview) is clicked.
     * 
     * @return void
     */

    OnClick = () => {

        this.setState( {

            show: true

        } );

    }

    /**
     * Callback for when the player is fully loaded.
     * 
     * @return void
     */

    OnReady = () => {

        this.setState( {

            ready: true

        } );

    }

    /**
     * Extract the ID from a YouTube video URL.
     * 
     * @param string url - Youtube URL.
     * 
     * @return string - The ID.
     */

    ParseUrl = ( url ) => {

        const id = url.match( /[a-z0-9\-_]{8,}/i );

        return id ? id[0] : false;

    }

    render() {

        const { className, poster } = this.props;
        const { id, ready, show } = this.state;
        const CA = [ "YoutubePlayer" ];
        const Content = [];

        if ( className ) {

            CA.push( className );

        }

        if ( id ) {

            CA.push( "HasVideo" );

            Content.push(

                <div
                
                    className="YoutubePlayerPoster"
                    key="poster"
                    onClick={ this.OnClick }
                
                >

                    <LoadImage
                    
                        className="YoutubePlayerPosterImage"
                        src={ poster || `https://img.youtube.com/vi/${id}/maxresdefault.jpg` }
                    
                    />

                    <div
                    
                        className="YoutubePlayerButton"
                    
                    ></div>

                </div>

            );

            if ( show ) {

                CA.push( "Show" );

                Content.push(

                    <div
                    
                        className="YoutubePlayerWrapper"
                        key="player"
                    
                    >

                        <Player
                    
                            onReady={ this.OnReady }
                            opts={ this.Options }
                            videoId={ id }
                            
                        />

                    </div>

                );

                if ( ready ) {

                    CA.push( "Ready" );

                }

            }

        }

        const CS = CA.join( " " );

        return(

            <div className={ CS }>
            
                { Content }
            
            </div>

        );

    }

}

YoutubePlayer.propTypes = {

    className: PropTypes.string,
    id: PropTypes.string,
    poster: PropTypes.string,
    url: PropTypes.string

};

YoutubePlayer.defaultProps = {

    className: "",
    id: "",
    poster: "",
    url: ""

};

export default YoutubePlayer;