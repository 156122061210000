
/**!
 *  Top section.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./top.scss";

import {

    Box3,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    PlaneGeometry,
    Scene as ThreeScene,
    ShaderMaterial,
    Texture,
    TextureLoader,
    Vector3,
    WebGLRenderer

} from "three";

import LoadImage from "Components/Layout/LoadImage";
import ScrollDown from "Components/UI/ScrollDown";

import GLTFLoader from "three-gltf-loader";
import RingModel from "Import/Models/ring-text-smooth-04.gltf";

import BgBlack from "./black.png";
import BgYellow from "./yellow2.jpg";
import EnvMap from "Import/Images/env_003.png";
import Logo from "./logo.png";

class Top extends React.Component {

    constructor( props ) {

        super( props );

        // Config
        this.CameraClipFar = 1000;
        this.CameraClipNear = 0;
        this.CameraDistance = 30;
        this.CameraFOV = 20;
        this.Interactive = false;
        this.Lights = false;
        this.Last = 0;
        this.LogoRatio = 698 / 284;
        this.RotationX = Math.PI * 0.7;
        this.RotationY = Math.PI * 0.25;
        this.RotationZ = 0;
        this.Scale = 1;

        // Read only
        this.AspectRatio = 0;
        this.Frames = 0;
        this.Loaded = false;
        this.MaxAnisotropy = 0;
        this.Offset = 0;
        this.PixelRatio = 1;
        this.Playing = false;
        this.SceneHeight = 0;
        this.SceneWidth = 0;

        // Scene objects
        this._Box = false;
        this._Camera = false;
        this._CameraTarget = false;
        this._Controls = false;
        this._Light1 = false;
        this._Light2 = false;
        this._Logo = false;
        this._Renderer = false;
        this._Ring = false;
        this._Scene = false;

        this.state = {

            fgLoaded: false,
            ready: false

        };

    }

    /**
     * Setup scene on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { container } = this.refs;

        this.AttributesUpdate();

        this._Scene = new ThreeScene();
        this._Camera = new PerspectiveCamera( this.CameraFOV, this.AspectRatio, this.CameraNear, this.CameraFar );
        this._CameraTarget = new Vector3( 0, 0, 0 );
        this._Renderer = new WebGLRenderer( { alpha: true } );
        this._DomElement = this._Renderer.domElement;

        this.AttributesSet();

        container.appendChild( this._DomElement );

        window.addEventListener( "focus", this.AttributesUpdateSet );
        window.addEventListener( "resize", this.AttributesUpdateSet );
        window.addEventListener( "scroll", this.OnScroll );

        this.Load();

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "focus", this.AttributesUpdateSet );
        window.removeEventListener( "resize", this.AttributesUpdateSet );
        window.removeEventListener( "scroll", this.OnScroll );

    }

    /**
     * Apply scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesSet = () => {

        if ( !this._Camera || !this._Renderer ) {

            return;

        }

        this._Camera.aspect = this.AspectRatio;
        this._Camera.updateProjectionMatrix();

        this._Renderer.setSize( this.SceneWidth, this.SceneHeight );
        this._Renderer.setClearColor( 0xffffff, 0 );
        this._Renderer.setPixelRatio( this.PixelRatio );

        this.MaxAnisotropy = this._Renderer.capabilities.getMaxAnisotropy();

        return this;

    }

    /**
     * Reset scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesUpdate = () => {

        const { container } = this.refs;

        this.SceneHeight = container.offsetHeight;
        this.SceneWidth = container.offsetWidth;
        this.AspectRatio = this.SceneWidth / this.SceneHeight;
        this.PixelRatio = window.devicePixelRatio;

        return this;

    }

    /**
     * Reset and apply scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesUpdateSet = () => {

        this.AttributesUpdate();
        this.AttributesSet();
        this.SetCameraPosition();

        return this;

    }

    /**
     * Load scene resources.
     * 
     * @return void
     */

    Load = () => {

        if ( this.Loaded ) {

            return;

        }

        this.Loaded = true;

        const Loader = new GLTFLoader();

        Loader.load(
            
            RingModel,

            // Loaded
            gltf => {

                this.LoadMaterial( ( ringMaterial, logoMaterial ) => {

                    this._Ring = new Mesh( gltf.scene.children[0].geometry, ringMaterial );
                    this._Ring.rotation.x = this.RotationX;
                    this._Ring.rotation.y = this.RotationY;
                    this._Ring.rotation.z = this.RotationZ;

                    const LogoH = 4;
                    const LogoW = LogoH * this.LogoRatio;
                    const LogoGeometry = new PlaneGeometry( LogoW, LogoH );

                    this._Logo = new Mesh( LogoGeometry, logoMaterial );
                    this._Logo.position.z = 0.4;

                    this._Scene.add( this._Ring, this._Logo );

                    this.SetCameraPosition();
                    this.Start();

                    this.setState( {

                        ready: true

                    } );

                } );

            }

        );

    }

    /**
     * Load and create the ring material.
     * 
     * @return void
     */

    LoadMaterial = ( callback ) => {

        const Loader = new TextureLoader();
        let _EnvMap = false;
        let _Logo = false;

        const OnLoad = () => {

            if ( !_EnvMap || !_Logo ) {

                return;

            }

            callback( new ShaderMaterial( {

                polygonOffset: true,
                polygonOffsetFactor: 1,
                polygonOffsetUnits: 1,
                uniforms: {

                    envMap: {

                        type: "t",
                        value: _EnvMap

                    }

                },
                vertexShader: "varying vec2 vN; void main() { vec3 e = normalize( vec3( modelViewMatrix * vec4( position, 1.0 ) ) ); vec3 n = normalize( normalMatrix * normal ); vec3 r = reflect( e, n ); float m = 2. * sqrt( pow( r.x, 2. ) + pow( r.y, 2. ) + pow( r.z + 1., 2. ) ); vN = r.xy / m + .5; gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1. );}",

                fragmentShader: "uniform sampler2D envMap; varying vec2 vN; void main() { vec3 base = texture2D( envMap, vN ).rgb; gl_FragColor = vec4( base, 1. ); }"

            } ), new MeshBasicMaterial( {

                map: _Logo,
                transparent: true,
                depthWrite: false,
                alphaTest: 0

            } ) );

        }

        Loader.load( EnvMap, envMap => {

            _EnvMap = envMap;
            OnLoad();

        } );

        Loader.load( Logo, logo => {

            logo.anisotropy = this.MaxAnisotropy;

            _Logo = logo;
            OnLoad();

        } );

    }

    /**
     * Render the logo plane texture.
     * 
     * @return object - Three texture instance.
     */

    Logo = ( width, height ) => {

        const Canvas = document.createElement( "canvas" );
        const Context = Canvas.getContext( "2d" );
        const W = width * 100;
        const H = height * 100;

        Canvas.width = W;
        Canvas.height = H;

        Context.fillStyle = "#ffe000";

        const Path = new Path2D( `M0 0H${W}V${H/2}H${W*.75}V${H}H${W*.25}V${H/2}H0Z` );

        Context.fill( Path );

        const Logo = new Texture( Canvas );
        
        Logo.anisotropy = Math.min( this.MaxAnisotropy, 2 );
        Logo.premultiplyAlpha = false;
        Logo.needsUpdate = true;

        return Logo;

    }

    /**
     * Callback when the black part of the background loads.
     * 
     * @return void
     */

    OnLoadFg = () => {

        this.setState( {

            fgLoaded: true

        } );

    }


    /**
     * Callback the user starts interacting with the ring.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnMouseDown = (e) => {

        const { button, pageX, pageY } = e;

        if ( !this.Interactive || button !== 0 || !this.Playing ) {

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        this.Origin = [ this.Offset, pageX, pageY ];

        window.addEventListener( "mousemove", this.OnMouseMove );
        window.addEventListener( "mouseup", this.OnMouseUp );
        document.addEventListener( "mouseleave", this.OnMouseUp );

    }

    /**
     * Callback the user is interacting with the ring.s.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnMouseMove = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const { pageX, pageY } = e;
        const [ O, SX, SY ] = this.Origin;
        const DX = pageX - SX;
        const DY = pageY - SY;
        
        this.Offset = O + DX * -.5 + DY * .5;

    }

    /**
     * Callback the user stops interacting with the ring.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnMouseUp = () => {

        this.Origin = false;

        window.removeEventListener( "mousemove", this.OnMouseMove );
        window.removeEventListener( "mouseup", this.OnMouseUp );
        document.removeEventListener( "mouseleave", this.OnMouseUp );

    }

    /**
     * Callback the user is interacting with the ring on touch screens.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnTouchMove = (e) => {

        if ( !this.Interactive ) {

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        const { touches } = e;
        const { pageX, pageY } = touches[0];
        const [ O, SX, SY ] = this.Origin;
        const DX = pageX - SX;
        const DY = pageY - SY;
        
        this.Offset = O + DX * -.5 + DY * .5;

    }

    /**
     * Callback the user stops interacting with the ring on touch screens.
     * 
     * @return void
     */

    OnTouchEnd = (e) => {

        if ( !this.Interactive ) {

            return;

        }

        this.Origin = false;

    }

    /**
     * Callback the user starts interacting with the ring on touch screens.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnTouchStart = (e) => {

        if ( !this.Interactive || !this.Playing ) {

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        const { touches } = e;
        const { pageX, pageY } = touches[0];

        this.Origin = [ this.Offset, pageX, pageY ];

    }

    /**
     * Callback when the user is scrolling through the window.
     * 
     * @return void
     */

    OnScroll = () => {

        if ( this.Interactive ) {

            return;

        }

        const S = window.scrollY;
        const D = Math.abs( S - this.Last );

        this.Last = S;
        this.Offset += D * .5;

    }

    /**
     * Render next frame.
     * 
     * @return void
     */

    RenderScene = () => {

        if ( !this.Playing ) {

            return;

        }

        requestAnimationFrame( this.RenderScene.bind( this ) );

        this._Ring.rotation.x = this.Offset * .01 + this.Frames * .001;

        this._Camera.lookAt( this._CameraTarget );
        this._Renderer.render( this._Scene, this._Camera );

        this.Frames++;

    }

    /**
     * Set camera position to fit the scene.
     * 
     * @return void
     */

    SetCameraPosition = () => {

        if ( !this._Camera || !this._Ring ) {

            return;

        }

        if ( !this._Box ) {

            this._Box = new Box3();

            this._Box.setFromObject( this._Ring );

        }

        const Size = this._Box.getSize( new Vector3( 0, 0, 0 ) );
        const Max = Math.max( Size.x, Size.y, Size.z );
        const Fov = this.CameraFOV * ( Math.PI / 180 );
        const Z = Math.abs( Max / 4 * Math.tan( Fov * 2 ) );
        const P = Z / Math.min( this.AspectRatio, 1 ) * 12.5;

        this._Camera.position.z = P;

    }

    /**
     * Start rendering.
     * 
     * @return void
     */

    Start = () => {

        if ( this.Playing ) {

            return this;

        }

        this.Playing = true;

        this.RenderScene();

        return this;

    }

    render() {

        const { fgLoaded, ready } = this.state;
        const CA = [ "Top" ];

        if ( !fgLoaded || !ready ) {

            CA.push( "NotReady" );

        }

        if ( this.Interactive ) {

            CA.push( "Interactive" );

        }

        else {

            CA.push( "NonInterative" );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                onMouseDown={ this.OnMouseDown }
                onTouchStart={ this.OnTouchStart }
                onTouchMove={ this.OnTouchMove }
                onTouchEnd={ this.OnTouchEnd }
                
            >

                <LoadImage
                
                    className="TopBackgroundYellow"
                    src={ BgYellow }
                    
                />

                <LoadImage
                
                    className="TopBackgroundBlack"
                    onLoad={ this.OnLoadFg }
                    src={ BgBlack }
                    
                />

                <div className="TopCanvasWrapper">

                    <div className="TopCanvasContainer" ref="container" />

                </div>

                <ScrollDown />
                
            </div>

        );
        
    }

}

export default Top;