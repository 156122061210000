
import React from "react";
import "./core.scss";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import Album from "Components/View/Album";
import Cookies from "Components/View/Cookies";
import Ebba from "Components/View/Ebba";
import Footer from "Components/View/Footer";
import Hampus from "Components/View/Hampus";
import Letter from "Components/View/Letter";
import Lnu from "Components/Layout/Lnu";
import Quotes from "Components/View/Quotes";
import ScrollBar from "Components/UI/ScrollBar";
import TestScene from "Components/View/TestScene";
import Top from "Components/View/Top";
import Video from "Components/View/Video";

class Core extends React.Component {

    constructor( props ) {

        super( props );

        const GA = "UA-60522338-5";
        const Pixel = "1216657948388895";

        if ( GA ) {

            ReactGA.initialize( GA );
            ReactGA.pageview( "/" );

        }

        if ( Pixel ) {

            ReactPixel.init( Pixel, null, {

                autoConfig: true,
                debug: false

            } );
            
            ReactPixel.pageView();

        }

        this.Test = 0;
        this.Touch = "ontouchstart" in window;
        
    }

    render() {

        if ( this.Test ) {

            return <TestScene />;

        }

        const CA = [ "Core" ];

        if ( this.Touch ) {

            CA.push( "Touch" );

        }

        else {

            CA.push( "NoTouch" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <Lnu />
                <Top />
                <Letter />
                <Album />
                <Ebba />
                <Hampus />
                <Video />
                <Quotes />
                <Footer />
                <ScrollBar />
                <Cookies />

            </div>

        );

    }

}

export default Core;