
/**!
 *  Cookie confirm dialog.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./cookies.scss";

import { default as JsCookie } from "js-cookie";

class Cookies extends React.Component {

    constructor( props ) {

        super( props );

        this.Key = "_dco2020_cookies";
        this.Url = "https://lnu.se/mot-linneuniversitetet/kontakta-och-besoka/personuppgifter/";

        this.state = {

            confirmed: true

        }

    }

    componentDidMount() {

        this.setState( {

            confirmed: JsCookie.get( this.Key )

        } );

    }

    Confirm = () => {

        JsCookie.set( this.Key, "1", { expires: 14 } );

        this.setState( {

            confirmed: true

        } );

    }

    render() {

        const { confirmed } = this.state;

        if ( confirmed ) {

            return "";

        }

        return (

            <div className="Cookies">

                <div className="CookiesContent">

                    <div className="CookiesText">Vi använder cookies på dearclassof2020.lnu.se. Genom att fortsätta vara här accepterar du att cookies används.</div>

                    <a
                    
                        className="CookiesLink"
                        href={ this.Url }
                        target="_blank"
                        rel="noopener noreferrer"
                    
                    >Lär mer om cookies</a>

                    <div className="CookiesConfirm" onClick={ this.Confirm }>Ok</div>

                </div>

            </div>

        );

    }

}

export default Cookies;
