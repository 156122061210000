
/**!
 *  Linnéuniversitetet logo.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./lnu.scss";

class Lnu extends React.Component {

    Svg = () => {

        return (

            <svg
            
                xmlns="http://www.w3.org/2000/svg"
                width="187.513"
                height="18.63"
                className="LnuSvg"
                viewBox="0 0 187.513 18.63"
                
            >
                <path d="M1423.629,63.979h3.763V78.344h7.135v3.425h-10.9Z" transform="translate(-1423.629 -63.521)"/>
                <path d="M1474.421,84.3h3.572v12.98h-3.572Z" transform="translate(-1462.396 -79.029)"/>
                <path d="M1499.636,85a4.416,4.416,0,0,1,3.81-1.905,4.254,4.254,0,0,1,3.739,1.929c.524.834.667,1.667.667,3.977v7.359h-3.573V88.736a3.744,3.744,0,0,0-.309-1.977,2.033,2.033,0,0,0-1.762-.929c-1.643,0-2.406,1.215-2.406,3.811v6.716h-3.572V83.378h3.406Z" transform="translate(-1479.041 -78.109)"/>
                <path d="M1554.495,85a4.416,4.416,0,0,1,3.81-1.905,4.255,4.255,0,0,1,3.739,1.929c.524.834.667,1.667.667,3.977v7.359h-3.573V88.736a3.741,3.741,0,0,0-.309-1.977,2.032,2.032,0,0,0-1.762-.929c-1.643,0-2.405,1.215-2.405,3.811v6.716h-3.573V83.378h3.406Z" transform="translate(-1520.912 -78.109)"/>
                <path d="M1671.7,95.658a4.448,4.448,0,0,1-3.811,1.906,4.335,4.335,0,0,1-3.739-1.929c-.524-.834-.666-1.667-.666-3.977V84.3h3.572v7.621a3.74,3.74,0,0,0,.31,1.977,1.981,1.981,0,0,0,1.762.929,2.228,2.228,0,0,0,1.929-1.119,4.89,4.89,0,0,0,.476-2.691V84.3h3.573v12.98H1671.7Z" transform="translate(-1606.7 -79.029)"/>
                <path d="M1722.316,85a4.418,4.418,0,0,1,3.811-1.905,4.254,4.254,0,0,1,3.739,1.929c.524.834.667,1.667.667,3.977v7.359h-3.573V88.736a3.741,3.741,0,0,0-.309-1.977,2.033,2.033,0,0,0-1.762-.929c-1.643,0-2.405,1.215-2.405,3.811v6.716h-3.573V83.378h3.406Z" transform="translate(-1649.002 -78.109)"/>
                <path d="M1792.943,84.3h3.882l2.525,9.836,2.572-9.836h3.81l-4.406,12.98h-4.1Z" transform="translate(-1705.508 -79.029)"/>
                <path d="M1857.6,92.1c-.81,2.81-3.025,4.335-6.24,4.335-4.049,0-6.621-2.715-6.621-6.978,0-4.025,2.62-6.763,6.5-6.763,3.93,0,6.384,2.738,6.384,7.1,0,.143,0,.476-.024.858h-9.193a2.843,2.843,0,0,0,3.024,3,2.312,2.312,0,0,0,2.382-1.548Zm-3.668-4.049a2.59,2.59,0,0,0-2.667-2.667c-1.62,0-2.573.9-2.882,2.667Z" transform="translate(-1745.042 -77.803)"/>
                <path d="M1903.787,83.3h3.405v1.691a3.876,3.876,0,0,1,3.6-2,2.428,2.428,0,0,1,.953.143v3.1a3.54,3.54,0,0,0-.976-.119c-2.453,0-3.4,1.239-3.4,4.43V96.28h-3.573Z" transform="translate(-1790.109 -78.031)"/>
                <path d="M1774.2,84.3h3.573v12.98H1774.2Z" transform="translate(-1691.202 -79.029)"/>
                <path d="M1945.4,87.216c-.071-1.381-.667-1.953-2.12-1.953-1.1,0-1.786.477-1.786,1.262,0,.572.429.929,1.405,1.191l2.573.667c2.572.666,3.738,1.857,3.738,3.81,0,2.6-2.167,4.239-5.644,4.239-3.786,0-5.906-1.667-5.953-4.716h3.644c-.024,1.381.761,2.1,2.262,2.1,1.191,0,1.977-.5,1.977-1.262,0-.81-.644-1.238-2.406-1.62-2.382-.5-3.239-.833-4.048-1.571a3.541,3.541,0,0,1-1.12-2.524c0-2.548,2.144-4.144,5.526-4.144,3.454,0,5.478,1.691,5.454,4.525Z" transform="translate(-1815.925 -77.803)"/>
                <path d="M2017.908,76.856h-2.1v5.811c0,1.405.382,1.953,1.358,1.953.167,0,.309-.024.738-.072v2.668a5.69,5.69,0,0,1-1.857.214A3.454,3.454,0,0,1,2012.883,86c-.5-.739-.643-1.477-.643-3.191V76.856h-1.906V74.164h1.906V71.021h3.572v3.143h2.1Z" transform="translate(-1871.431 -68.896)"/>
                <path d="M2055.365,92.1c-.81,2.81-3.025,4.335-6.24,4.335-4.049,0-6.62-2.715-6.62-6.978,0-4.025,2.619-6.763,6.5-6.763,3.929,0,6.383,2.738,6.383,7.1,0,.143,0,.476-.024.858h-9.193a2.842,2.842,0,0,0,3.025,3,2.311,2.311,0,0,0,2.381-1.548Zm-3.668-4.049a2.59,2.59,0,0,0-2.668-2.667c-1.62,0-2.572.9-2.881,2.667Z" transform="translate(-1895.985 -77.803)"/>
                <path d="M2104.573,76.856h-2.1v5.811c0,1.405.381,1.953,1.358,1.953.167,0,.31-.024.738-.072v2.668a5.691,5.691,0,0,1-1.858.214A3.454,3.454,0,0,1,2099.547,86c-.5-.739-.644-1.477-.644-3.191V76.856H2097V74.164h1.905V71.021h3.573v3.143h2.1Z" transform="translate(-1937.577 -68.896)"/>
                <path d="M2142.028,92.1c-.809,2.81-3.025,4.335-6.239,4.335-4.049,0-6.621-2.715-6.621-6.978,0-4.025,2.62-6.763,6.5-6.763,3.929,0,6.381,2.738,6.381,7.1,0,.143,0,.476-.023.858h-9.193a2.843,2.843,0,0,0,3.025,3,2.312,2.312,0,0,0,2.382-1.548Zm-3.667-4.049a2.59,2.59,0,0,0-2.667-2.667c-1.62,0-2.572.9-2.882,2.667Z" transform="translate(-1962.131 -77.803)"/>
                <path d="M2191.238,76.856h-2.1v5.811c0,1.405.381,1.953,1.357,1.953.167,0,.31-.024.739-.072v2.668a5.692,5.692,0,0,1-1.858.214A3.453,3.453,0,0,1,2186.213,86c-.5-.739-.643-1.477-.643-3.191V76.856h-1.906V74.164h1.906V71.021h3.572v3.143h2.1Z" transform="translate(-2003.725 -68.896)"/>
                <path d="M1991.164,84.3h3.572v12.98h-3.572Z" transform="translate(-1856.8 -79.029)"/>
                <g transform="translate(42.807 4.888)">
                    <path d="M1617.3,92.1c-.809,2.81-3.024,4.335-6.239,4.335-4.049,0-6.621-2.715-6.621-6.978,0-4.025,2.62-6.763,6.5-6.763,3.93,0,6.383,2.738,6.383,7.1,0,.143,0,.476-.024.858h-9.193a2.842,2.842,0,0,0,3.025,3,2.311,2.311,0,0,0,2.382-1.548Zm-3.667-4.049a2.59,2.59,0,0,0-2.667-2.667c-1.62,0-2.572.9-2.882,2.667Z" transform="translate(-1604.439 -82.691)"/>
                </g>
                <path d="M1477.6,64.008a1.963,1.963,0,1,1-1.963-1.963A1.963,1.963,0,0,1,1477.6,64.008Z" transform="translate(-1461.827 -62.045)"/>
                <path d="M1777.381,64.008a1.963,1.963,0,1,1-1.962-1.963A1.963,1.963,0,0,1,1777.381,64.008Z" transform="translate(-1690.633 -62.045)"/>
                <path d="M1994.344,64.008a1.963,1.963,0,1,1-1.963-1.963A1.963,1.963,0,0,1,1994.344,64.008Z" transform="translate(-1856.23 -62.045)"/>
                <path d="M1643.914,64.007a1.963,1.963,0,1,1-1.962-1.963A1.963,1.963,0,0,1,1643.914,64.007Z" transform="translate(-1588.765 -62.044)"/>
            </svg>

        );

    }

    render() {

        return (

            <a
            
                className="Lnu"
                href="https://lnu.se/"
                rel="noopener noreferrer"
                target="_blank"
                
            >

                { this.Svg() }

            </a>

        );

    }

}

export default Lnu;