
/**!
 *  Scene for test rendering.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./testscene.scss";

import {

    AmbientLight,
    DirectionalLight,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    PerspectiveCamera,
    PlaneGeometry,
    Scene as ThreeScene,
    ShaderMaterial,
    TextureLoader,
    Vector2,
    Vector3,
    WebGLRenderer

} from "three";

import GLTFLoader from "three-gltf-loader";
import OrbitControls from "three-orbitcontrols";
import RingModel from "Import/Models/ring-text-smooth-04.gltf";

import EnvMap from "Import/Images/env_003.png";
import NormalMap from "Import/Images/normals_005.png";

class TestScene extends React.Component {

    constructor( props ) {

        super( props );

        // Config
        this.CameraClipFar = 1000;
        this.CameraClipNear = 0;
        this.CameraDistance = 30;
        this.CameraFOV = 20;
        this.CaptureMode = 1;
        this.CaptureSize = [ 1920, 1080 ];
        this.CaptureDuration = 6;
        this.CaptureFramerate = 30;
        this.CaptureFrames = this.CaptureDuration * this.CaptureFramerate;
        this.CaptureMask = 0;
        this.CapturePrefix = "ring-002";
        this.CaptureStep = Math.PI / ( this.CaptureFrames * .5 );
        this.Lights = false;
        this.Material = 2;
        this.RotationX = 0;//Math.PI * 0.7;
        this.RotationY = Math.PI * 0.25;
        this.RotationZ = 0;
        this.Scale = 1;
        this.SetNormals = false;

        // Read only
        this.AspectRatio = 0;
        this.Frames = 0;
        this.Loaded = false;
        this.PixelRatio = 1;
        this.Playing = false;
        this.SceneHeight = 0;
        this.SceneWidth = 0;

        // Scene objects
        this._Camera = false;
        this._CameraTarget = false;
        this._Controls = false;
        this._Light1 = false;
        this._Light2 = false;
        this._Renderer = false;
        this._Ring = false;
        this._Scene = false;

        this.state = {

            capturing: false,
            frames: 0

        }

    }

    /**
     * Setup scene on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { container } = this.refs;

        this.AttributesUpdate();

        this._Scene = new ThreeScene();
        this._Camera = new PerspectiveCamera( this.CameraFOV, this.AspectRatio, this.CameraNear, this.CameraFar );
        this._CameraTarget = new Vector3( 0, 0, 0 );
        this._Renderer = new WebGLRenderer( { alpha: true, preserveDrawingBuffer: this.CaptureMode } );
        this._DomElement = this._Renderer.domElement;
        this._Controls = this.CaptureMode ? false : new OrbitControls( this._Camera, this._DomElement );

        this.AttributesSet();

        container.appendChild( this._DomElement );
        window.addEventListener( "resize", this.AttributesUpdateSet );

        this.Load();

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.AttributesUpdateSet );

    }

    /**
     * Apply scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesSet = () => {

        this._Camera.aspect = this.AspectRatio;
        this._Camera.updateProjectionMatrix();

        this._Renderer.setSize( this.SceneWidth, this.SceneHeight );
        this._Renderer.setClearColor( 0xffffff, 0 );
        this._Renderer.setPixelRatio( this.PixelRatio );

        return this;

    }

    /**
     * Reset scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesUpdate = () => {

        const { container } = this.refs;

        this.SceneHeight = this.CaptureMode ? this.CaptureSize[1] : container.offsetHeight;
        this.SceneWidth = this.CaptureMode ? this.CaptureSize[0] : container.offsetWidth;
        this.AspectRatio = this.SceneWidth / this.SceneHeight;
        this.PixelRatio = this.CaptureMode ? 1 : window.devicePixelRatio;

        return this;

    }

    /**
     * Reset and apply scene attributes.
     * 
     * @return object - Class instance.
     */

    AttributesUpdateSet = () => {

        this.AttributesUpdate();
        this.AttributesSet();

        return this;

    }

    /**
     * Capture and upload the current frame.
     * 
     * @return void
     */

    CaptureScene = ( frame = false ) => {

        const { frames } = this.state;
        const Frame = frame !== false ? frame : frames;

        if ( !this.Playing ) {

            return;

        }

        const ImageData = this._DomElement.toDataURL( "image/png" );

        this.Upload( ImageData, Frame, success => {

            if ( !this.Playing || !success ) {

                return;

            }

            const Next = Frame + 1;

            if ( Next >= this.CaptureFrames ) {

                this.Playing = false;

                this.setState( {

                    capturing: false,
                    frames: Next

                } );

            }

            else {

                this.setState( { frames: Next } );
            
                this.RenderScene();

            }
            
        } );

    }

    /**
     * Load scene resources.
     * 
     * @return void
     */

    Load = () => {

        if ( this.Loaded ) {

            return;

        }

        this.Loaded = true;

        const Loader = new GLTFLoader();

        Loader.load(
            
            RingModel,

            // Loaded
            gltf => {

                this.LoadMaterial( material => {

                    this._Ring = new Mesh( gltf.scene.children[0].geometry, material );
                    this._Ring.rotation.x = this.RotationX;
                    this._Ring.rotation.y = this.RotationY;
                    this._Ring.rotation.z = this.RotationZ;

                    if ( this.SetNormals ) {

                        this._Ring.geometry.computeFaceNormals();
                        this._Ring.geometry.computeVertexNormals();

                    }

                    this._Scene.add( this._Ring );

                    if ( this.Lights ) {

                        this._Light1 = new AmbientLight( 0x606060 );
                        this._Light2 = new DirectionalLight( 0x606060 );
                        this._Light2.position.set( 0.75, 0.75, 1.0 ).normalize();

                        this._Scene.add( this._Light1, this._Light2 );

                    }

                    if ( this.CaptureMask ) {

                        const MaskGeometry = new PlaneGeometry( 100, 100 );
                        const MaskMaterial = new MeshBasicMaterial( { color: 0xffffff } );
                        const MaskPlane = new Mesh( MaskGeometry, MaskMaterial );

                        this._Scene.add( MaskPlane );

                    }

                    this.SetCameraPosition();

                    if ( this.CaptureMode ) {

                        this.RenderOnce();

                    }

                    else {

                        this.Start();

                    }

                } );

            }

        );

    }

    /**
     * Load and create the ring material.
     * 
     * @return void
     */

    LoadMaterial = ( callback ) => {

        const Loader = new TextureLoader();

        if ( this.CaptureMask ) {

            callback( new MeshBasicMaterial( {

                color: 0x000000

            } ) );

            return;

        }

        switch ( this.Material ) {

            case 1:

                let _EnvMap = false;
                let _NormalMap = false;

                const OnLoadCustom = () => {

                    if ( !_EnvMap || !_NormalMap ) {

                        return;

                    }

                    callback( new ShaderMaterial( {

                        polygonOffset: true,
                        polygonOffsetFactor: 1,
                        polygonOffsetUnits: 1,
                        uniforms: {

                            envMap: {

                                type: "t",
                                value: _EnvMap

                            },

                            nrmMap: {

                                type: "t",
                                value: _NormalMap

                            },

                            nrmScale: {

                                type: "f",
                                value: 0.5

                            }

                        },
                        vertexShader: `

                            varying mat4 mMatrix;
                            varying mat3 nMatrix;
                            varying vec3 vN;
                            varying vec3 vP;
                            varying vec2 vUv;
                            
                            void main() {

                                mMatrix = modelViewMatrix;
                                nMatrix = normalMatrix;
                                vP = position;
                                vN = normal;
                                vUv = uv;

                                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                                
                            }
                            
                        `,

                        fragmentShader: `
                        
                            uniform sampler2D envMap;
                            uniform sampler2D nrmMap;
                            uniform float nrmScale;

                            varying mat4 mMatrix;
                            varying mat3 nMatrix;
                            varying vec3 vP;
                            varying vec3 vN;
                            varying vec2 vUv;
                            
                            void main() {

                                vec4 vNrm = 2.0 * texture2D( nrmMap, vUv, -1.0 ) - 1.0;
                                vec3 uNrm = normalize( vNrm.rgb );

                                vec3 e = normalize( vec3( mMatrix * vec4( vP, 1.0 ) ) );
                                vec3 n = normalize( nMatrix * vN + uNrm * nrmScale );
                                vec3 r = reflect( e, n );

                                float m = 2. * sqrt( pow( r.x, 2. ) + pow( r.y, 2. ) + pow( r.z + 1., 2. ) );
                                
                                vec2 envN = r.xy / m + .5;
                                vec3 base = texture2D( envMap, envN ).rgb;
                                
                                gl_FragColor = vec4( base, 1. );
                                
                            }
                            
                        `

                    } ) );

                };

                Loader.load( EnvMap, envMap => {

                    _EnvMap = envMap;
                    OnLoadCustom();

                } );

                Loader.load( NormalMap, normalMap => {

                    _NormalMap = normalMap;
                    OnLoadCustom();

                } );

                break;

            case 2:

                Loader.load( EnvMap, envMap => {

                    callback( new ShaderMaterial( {

                        polygonOffset: true,
                        polygonOffsetFactor: 1,
                        polygonOffsetUnits: 1,
                        uniforms: {

                            envMap: {

                                type: "t",
                                value: envMap

                            }

                        },
                        vertexShader: "varying vec2 vN; void main() { vec3 e = normalize( vec3( modelViewMatrix * vec4( position, 1.0 ) ) ); vec3 n = normalize( normalMatrix * normal ); vec3 r = reflect( e, n ); float m = 2. * sqrt( pow( r.x, 2. ) + pow( r.y, 2. ) + pow( r.z + 1., 2. ) ); vN = r.xy / m + .5; gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1. );}",

                        fragmentShader: "uniform sampler2D envMap; varying vec2 vN; void main() { vec3 base = texture2D( envMap, vN ).rgb; gl_FragColor = vec4( base, 1. ); }"

                    } ) );

                } );

                break;

            default:

                Loader.load( NormalMap, normalMap => {

                    callback( new MeshPhongMaterial( {
                            
                        color: 0x00ff00,
                        metalness: 1,
                        normalMap,
                        normalScale: new Vector2( 2, 2 ),
                        roughness: 0
                        
                    } ) );

                } );

        }

    }

    /**
     * Callback when the capture toolbar item is clicked.
     * 
     * @return void
     */

    OnCapture = () => {

        const { capturing } = this.state;

        if  ( capturing ) {

            this.setState( {
                
                capturing: false,
                frames: 0
                
            } );

            this.Playing = false;

        }

        else {

            this.setState( {
                
                capturing: true,
                frames: 0
                
            } );

            this.Playing = true;
            this.Frames = 0;

            this.RenderOnce();
            this.CaptureScene(0);

        }

    }

    /**
     * Render one frame.
     * 
     * @return void
     */

    RenderOnce = () => {

        this._Camera.lookAt( this._CameraTarget );
        this._Renderer.render( this._Scene, this._Camera );

    }

    /**
     * Render next frame.
     * 
     * @return void
     */

    RenderScene = () => {

        if ( !this.Playing ) {

            return;

        }

        if ( !this.CaptureMode ) {

            requestAnimationFrame( this.RenderScene.bind( this ) );

        }

        this._Ring.rotation.x = this.CaptureMode ? this.Frames * this.CaptureStep : this.Frames * .001;

        this._Camera.lookAt( this._CameraTarget );
        this._Renderer.render( this._Scene, this._Camera );

        if ( this.CaptureMode ) {

            this.CaptureScene();

        }

        this.Frames++;

    }

    /**
     * Set camera position.
     * 
     * @return void
     */

    SetCameraPosition = () => {

        const L = this.CameraDistance * this.Scale;
        const X = 0;
        const Y = 0;
        const Z = L;

        this._Camera.position.x = X;
        this._Camera.position.y = Y;
        this._Camera.position.z = Z;

        if ( this._Controls ) {

            this._Controls.update();

        }

    }

    /**
     * Start rendering.
     * 
     * @return void
     */

    Start = () => {

        if ( this.Playing ) {

            return this;

        }

        this.Playing = true;

        this.RenderScene();

        return this;

    }

    /**
     * Upload a frame to the capture server.
     * 
     * @param string data - Image data.
     * @param integer frame - Frame number.
     * @param function callback - Callback when finished.
     * 
     * @return void
     */

    Upload = ( data, frame, callback ) => {

        const Frame = frame.toString().padStart( 3, "0" );
        const Mode = this.CaptureMask ? "mask" : "diffuse";
        const Name = `${this.CapturePrefix}-${Mode}-${Frame}`;

        const Xhr = new XMLHttpRequest();
        const Data = new FormData();

        Data.append( "filename", Name );
        Data.append( "filedata", data );
        Data.append( "sequence", this.CapturePrefix );

        Xhr.open( "POST", "http://bjorn.local/lnu-capture/", true );

        Xhr.addEventListener( "load", (e) => {

            if ( Xhr.readyState !== XMLHttpRequest.DONE ) {

                return;

            }

            callback( true );

        }, false );

        Xhr.addEventListener( "error", (e) => {

            callback( false );

        }, false );

        Xhr.send( Data );

    }

    render() {

        const { capturing, frames } = this.state;
        const CA = [ "TestScene" ];

        if ( this.CaptureMode ) {

            CA.push( "CaptureMode" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <div className="TestSceneCanvasContainer" ref="container" />

                { this.CaptureMode ? (

                    <div className="TestSceneToolbar">

                        <div
                        
                            className="TestSceneToolbarItem"
                            onClick={ this.OnCapture }
                            
                        >{ capturing ? "Stop" : "Start" }</div>

                    </div>

                ) : "" }

                { this.CaptureMode ? (

                    <div className="TestSceneInfo">

                        { capturing ? "Capturing" : "Capture" } { this.CaptureMask ? "mask" : "diffuse" } at { this.CaptureSize[0] }x{ this.CaptureSize[1] }
                        { capturing ? `... ${frames} of ${this.CaptureFrames} frames captured.` : "." }

                    </div>

                ) : "" }

            </div>

        );

    }

}

export default TestScene;