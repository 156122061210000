
/**!
 *  Letter section.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./letter.scss";

import Center from "Components/Layout/Center"

class Letter extends React.Component {

    render() {

        return (
        
            <Center className="Page Letter">

                    <div className="LetterContent">

                        <h2>Kära avgångsklass 2020,</h2>

                        <p>Nu är det dags att lämna gymnasiet. Vi f<span>ö</span>rstår att världen kan kännas hopplös, men f<span>ö</span>rsök att inte oroa er allt f<span>ö</span>r mycket. I låten Dear Class of 2020, har vi samlat några råd att ta med längs vägen. De kommer från flera av de smartaste personerna vi vet. Lite som en energiboost inför framtiden, helt enkelt.</p>

                        <p>När ni är redo att lösa världsproblemen är ni välkomna till Linnéuniversitetet. Till dess – lyssna på Dear Class of 2020, du hittar den här nedanför.</p>

                    </div>

            </Center>

        );

    }

}

export default Letter;
