
/**!
 *  Ebba Witt-Brattström
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Photo from "./ebba.jpg";

import LoadImage from "Components/Layout/LoadImage";

class Ebba extends React.Component {

    render() {

        return (

            <div className="PageText">

                <LoadImage
                
                    className="PageTextImage"
                    src={ Photo }
                
                />

                <h2>Ebba Witt-Brattström</h2>
                <h4>Rösten i Dear Class of 2020,</h4>

                <p>Ebba Witt-Brattström är författare, professor i litteraturvetenskap och en av den svenska kvinnokampens förgrundsgestalter. I jakten på en röst som kunde framföra alla tankar i Dear Class of 2020, med pondus, värme och övertygelse blev Ebba det självklara valet.</p>

            </div>

        );

    }

}

export default Ebba;