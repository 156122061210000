
/**!
 *  Hampus Lindvall
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Photo from "./hampus.jpg";

import LoadImage from "Components/Layout/LoadImage";

class Hampus extends React.Component {

    render() {

        return (

            <div className="PageText">

                <LoadImage
                
                    className="PageTextImage"
                    src={ Photo }
                
                />

                <h2>Hampus Lindvall</h2>
                <h4>Låtskrivare och producent</h4>

                <p>Musiken till Dear Class of 2020, är skriven och producerad av Hampus Lindvall. Hampus arbetar både i Sverige och utomlands, och har ett flertal framgångsrika hits på sitt CV. Han är främst känd för sitt arbete med artister som Zara Larsson, Icona Pop, Sigala och Benjamin Ingrosso.</p>

            </div>

        );

    }

}

export default Hampus;