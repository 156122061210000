
/**!
 *  Quotes section.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./quotes.scss";

import Parser from "Class/Parser";

class Quotes extends React.Component {

    constructor( props ) {

        super( props );

        this.Quotes = [

            [ "", "0:00", "Alexandra Strömberg", "Företagsledare", "Tidigare student vid Linnéuniversitetet" ],
            [ "", "0:00", "Anna Brolin", "Journalist på TV4", "Tidigare student vid Linnéuniversitetet" ],
            [ "Var dig själv utan att be om ursäkt.", "0:00", "Babba C Rivera", "PR-entreprenör", "" ],
            [ "Allt eller inget? Glöm det! Våga stanna upp, prioritera och fokusera din tid.", "0:00", "Carolina Klüft", "Friidrottare, programledare", "Tidigare student vid Linnéuniversitetet" ],
            [ "", "0:00", "Carro Levy", "Poddmakare, Heja Livet", "" ],
            [ "", "0:00", "Dilsa Demirbag Sten", "Frilansjournalist, Berättaministeriet", "" ],
            [ "Ibland har man inga tydliga planer, och det är helt okej.", "0:00", "Ebtisam Ahmed Mohammedsalih", "Årets IT-tjej 2019 ", "Student vid Linnéuniversitetet" ],
            [ "", "0:00", "Emely Crona Stenberg", "Poddmakare, Heja Livet", "" ],
            [ "Ingen har dött av att göra bort sig.", "0:00", "Irena Pozar", "Chefredaktör, Veckorevyn", "" ],
            [ "", "0:00", "Jarone Pinhassi", "Professor i ekologi, Linnéuniversitetet", "" ],
            [ "", "0:00", "Jenny Borg", "Poddmakare Spöktimmen", "Tidigare student vid Linnéuniversitetet" ],
            [ "", "0:00", "Johan Bergh", "Professor i skog- och träteknik, Linnéuniversitetet", "" ],
            [ "", "0:00", "Lars-Johan Jarnheimer", "Styrelseordförande för Telia", "Tidigare student vid Linnéuniversitetet" ],
            [ "", "0:00", "Lena Ek", "Styrelseordförande, Södra", "" ],
            [ "", "0:00", "Maria Arnholm", "Landshövding, Kronobergs län", "" ],
            [ "Stå upp f<span>ö</span>r vad du tror på, även när det skapar dålig stämning.", "0:00", "Mathilda Tham", "Professor i design, Linnéuniversitetet", "" ],
            [ "", "0:00", "Niklas Rådström", "Professor i kreativt skrivande, Linnéuniversitetet", "" ],
            [ "", "0:00", "Per Bauhn", "Professor i praktisk filosofi, Linnéuniversitetet", "" ],
            [ "", "0:00", "Per Strömblad", "Docent i statsvetenskap, Linnéuniversitetet", "" ],
            [ "Om du får en chans att ge dig ut i världen – ta den.", "0:00", "Pernilla Baralt", "Generalsekreterare UNICEF Sverige", "Tidigare student vid Linnéuniversitetet" ],
            [ "", "0:00", "Peter Aronsson", "Rektor och professor i historia, Linnéuniversitetet", "" ],

        ];

    }

    render() {

        const Quotes = [];

        this.Quotes.forEach( ( [ quote, timestamp, name, position, about ], index ) => {

            Quotes.push(

                <div className="QuotesItem" key={ index }>

                    { Parser.Parse( quote, "q" ) }

                    <div className="QuotesItemInfo">

                        <div className="QuotesItemTimestamp"></div>
                        <div className="QuotesItemWho">

                            <div className="QuotesItemName">{ name }</div>
                            <div className="QuotesItemPosition">{ position }</div>
                            <div className="QuotesItemAbout">{ about }</div>

                        </div>

                    </div>

                </div>

            );

        } );

        return (

            <div className="Quotes">

                <div className="QuotesTop">

                    <h2>Medverkande</h2>

                    <p>Alla tankar och idéer i Dear Class of 2020, kommer från följande smarta och inspirerande&nbsp;personer</p>

                </div>

                <div className="QuotesItems">

                    { Quotes }
                    
                </div>

            </div>

        );

    }

}

export default Quotes;