
/**!
 *  Scroll down button.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./scrolldown.scss";

class ScrollDown extends React.Component {

    render() {

        return (

            <a
            
                className="ScrollDown"
                href="#listen"
            
            >
                
                <div className="ScrollDownButton">Spela nu på Spotify</div>
                
            </a>

        );

    }

}

export default ScrollDown;