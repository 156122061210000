
/**!
 *  Video section.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./video.scss";

import Poster from "./poster.jpg";
import YoutubePlayer from "Components/UI/YoutubePlayer";

class Video extends React.Component {

    constructor( props ) {

        super( props );

        this.Url = "https://www.youtube.com/watch?v=gcId0ffuIPQ";

    }

    render() {

        return (

            <div className="Video">

                <YoutubePlayer
                
                    className="PageTextPlayer"
                    poster={ Poster }
                    url={ this.Url }
                
                />

                <a
                
                    className="VideoLink"
                    href={ this.Url }
                    target="_blank"
                    rel="noopener noreferrer"
                
                >Se musikvideon</a>

            </div>

        );

    }

}

export default Video;