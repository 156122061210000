
/**!
 *  Scrollbar
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./scrollbar.scss";

class ScrollBar extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            difference: false

        };

    }

    componentDidMount() {

        this.OnScroll();

        window.addEventListener( "scroll", this.OnScroll );
        window.addEventListener( "resize", this.OnScroll );

    }

    componentWillUnmount() {

        window.removeEventListener( "scroll", this.OnScroll );
        window.removeEventListener( "resize", this.OnScroll );

    }

    OnMouseDown = (e) => {

        e.stopPropagation();
        e.preventDefault();

        document.documentElement.classList.add( "Scrolling" );

        window.addEventListener( "mousemove", this.OnMouseMove );
        window.addEventListener( "mouseup", this.OnMouseUp );

    }

    OnMouseMove = (e) => {

        e.stopPropagation();
        e.preventDefault();

        const { bar } = this.refs;
        const { pageY: Y } = e;
        const { offsetHeight: H, offsetTop: O } = bar;
        const { scrollY: S, innerHeight } = window;
        const M = document.body.scrollHeight - innerHeight;

        window.scrollTo( 0, ( Y - O - S ) / H * M );

    }

    OnMouseUp = (e) => {

        this.OnMouseMove(e);

        document.documentElement.classList.remove( "Scrolling" );

        window.removeEventListener( "mousemove", this.OnMouseMove );
        window.removeEventListener( "mouseup", this.OnMouseUp );

    }

    OnScroll = () => {

        const { difference } = this.state;
        const { fill } = this.refs;
        const S = window.scrollY;
        const H = document.body.scrollHeight - window.innerHeight;
        const P = S / H;
        const D = P > .5;

        if ( D !== difference ) {

            this.setState( { difference: D } );

        }
        
        fill.style.height = P * 100 + "%";

    }

    render() {

        const { difference } = this.state;
        const CA = [ "ScrollBar" ];

        if ( difference ) {

            CA.push( "Difference" );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                onMouseDown={ this.OnMouseDown }
                ref="bar"
                
            >

                <div className="ScrollBarFill" ref="fill" />

            </div>

        );

    }

}

export default ScrollBar;
