
/**!
 *  Album section.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./album.scss";

import Center from "Components/Layout/Center"

class Album extends React.Component {

    render() {

        return (
        
            <Center id="listen" className="Page Album">

                <div className="AlbumContent">

                    <div className="AlbumPlayerWrapper">

                        <div className="AlbumPlayer">

                            <iframe
                            
                                width="100%"
                                height="100%"
                                allow="encrypted-media"
                                allowtransparency="true"
                                frameBorder="0"
                                title="Dear Class of 2020,"
                                src="https://open.spotify.com/embed/track/7KydmZH07lG2G61L8SAejW"
                                
                            ></iframe>

                        </div>

                    </div>

                </div>

            </Center>

        );

    }

}

export default Album;
